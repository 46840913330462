import { QueryClient } from "react-query";
import { ContentResponse } from "../../types/ContentResponse";
import { FeedResponseData } from "../useFetchInfiniteFeed";

interface HandleErrorInPaginatedCacheProps {
  queryClient: QueryClient;
  queryKey: string;
  pageNumber?: number;
  storyId: string;
  context: ContentResponse;
}

export const handleErrorInPaginatedCache = ({
  queryClient,
  queryKey,
  pageNumber,
  storyId,
  context,
}: HandleErrorInPaginatedCacheProps) => {
  queryClient.setQueryData<{ pages: FeedResponseData[] } | undefined>(
    queryKey,
    (data: { pages: FeedResponseData[] } | undefined) => {
      if (data && pageNumber !== undefined) {
        const newData = { ...data };
        const pageToUpdate = newData.pages[pageNumber];
        const storyToUpdateIndex = pageToUpdate?.Stories?.findIndex(
          (story) => story.Id === storyId
        );

        if (
          storyToUpdateIndex !== -1 &&
          context &&
          pageToUpdate.Stories &&
          storyToUpdateIndex
        ) {
          pageToUpdate.Stories[storyToUpdateIndex] = context;
        }
        return newData;
      }
      return data;
    }
  );
};

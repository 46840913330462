import { AxiosResponse } from "axios";
import { apiClient } from "../../apiClient";
import { FeedResponseData } from "./useFetchInfiniteFeed";
import { useQuery } from "react-query";
import { getDateId } from "./functions/getDateId";
import { transformStoriesData } from "./functions/transformStoriesData";
import { ContentResponse } from "../types/ContentResponse";

export const useGetTopDailyNews = () => {
  const fetchDailyNews = async (
    dateId: string = ""
  ): Promise<FeedResponseData | null> => {
    try {
      let currentDateId: string = dateId;
      let daysInThePast: number = 0;
      let isStoriesEmpty: boolean = true;

      while (isStoriesEmpty && daysInThePast <= 7) {
        const response: AxiosResponse<FeedResponseData | null> =
          await apiClient.get(`/stories/top?dateid=${currentDateId}`);
        const data = response.data;

        if (data && data.Stories && data.Stories.length > 0) {
          isStoriesEmpty = false;
          const content: ContentResponse[] = data.Stories.map((story) =>
            transformStoriesData(story)
          );

          return {
            Stories: content,
            LastReadNewsId: data.LastReadNewsId,
            DateId: data.DateId,
          };
        }

        daysInThePast += 1;
        currentDateId = getDateId(daysInThePast);
      }
      throw new Error("No top daily stories found in the last week");
    } catch (error) {
      console.error(error);
      throw new Error("Failed to fetch daily news");
    }
  };

  return useQuery("getDailyTopNews", () => fetchDailyNews(), {
    refetchOnWindowFocus: false,
  });
};

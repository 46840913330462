import React from "react";
import SignInButtonWithRedirect from "../auth/SignInButtonWithRedirect";
import styles from "./Welcome.module.css";
import Logo from "../components/icons/paper planes final_webpage transparent.png";

const Welcome: React.FC = () => {
  return (
    <div className={styles.welcome_wrapper}>
      <div className={styles.box}>
        <div className={styles.upperBox}>
          <img src={Logo} alt="logo" className={styles.logo} />
        </div>
        <div className={styles.lowerBox}>
          <h1>All News around the World</h1>
        </div>
      </div>
      <div className={styles.buttonBox}>
        <SignInButtonWithRedirect />
      </div>
    </div>
  );
};

export default Welcome;

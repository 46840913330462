import "./DiscussionsOverview.css";
import { SetStateAction, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDebounce } from "react-use";
import { useQueryClient } from "react-query";
import { useGetPostActivities } from "../customHooks/useGetPostActivities";
import NavDiscussions, {
  RecipientsDiscussionNavigation,
} from "./NavDiscussions";
import SingleDiscussion from "./SingleDiscussion";
import SearchResultsView from "./SearchResultsView";
import ExpandableSearchInput from "../components/elements/ExpandableSearchInput";
import TypeComment from "./TypeComment";
import { DiscussionType } from "../types/DiscussionType";
import {
  SubscriptionType,
  useMySubscription,
} from "../subscriptions/hooks/useMySubscription";
import GetPremiumButton from "../subscriptions/GetPremiumButton";
import { CircularProgress } from "@mui/material";

interface DiscussionsProps {
  display: boolean;
  storyDateId: string;
  storyId: string;
  showDiscussionsView: React.Dispatch<SetStateAction<boolean>>;
}

export type RecipientState = {
  recipientId: string;
  recipientType: DiscussionType;
};

function DiscussionsOverview({
  display,
  storyDateId,
  storyId,
  showDiscussionsView,
}: DiscussionsProps) {
  const {
    discussion,
    recipientId: urlRecipientId,
    recipientType: urlRecipientType,
  } = useParams<{
    discussion: "true";
    recipientId: string;
    recipientType: DiscussionType;
  }>();

  const [focusedRecipient, setFocusedRecipient] = useState<RecipientState>(
    urlRecipientId &&
      (urlRecipientType === "Group" || urlRecipientType === "User")
      ? {
          recipientId: urlRecipientId,
          recipientType: urlRecipientType,
        }
      : {
          recipientId: "World",
          recipientType: "World",
        }
  );

  const [recipients, setRecipients] = useState<
    RecipientsDiscussionNavigation[] | undefined
  >([]);

  const [searchVisible, setSearchVisible] = useState<boolean>(false);
  const [searchPhrase, setSearchPhrase] = useState<string>("");
  const [debouncedSearchPhrase, setDebouncedSearchPhrase] =
    useState<string>("");
  const [discussionLoaded, setDiscussionLoaded] = useState<boolean>(false);

  const queryClient = useQueryClient();
  const navigate = useNavigate();

  useDebounce(() => setDebouncedSearchPhrase(searchPhrase), 500, [
    searchPhrase,
  ]);

  const { data: activities, isLoading: isLoadingActivities } =
    useGetPostActivities({
      storyDateId,
      storyId,
      display,
    });

  const {
    data: mySubscriptionStatus,
    isLoading: isLoadingMySubscriptionStatus,
  } = useMySubscription();

  useEffect(() => {
    if (activities?.activity?.activeRecipients) {
      setRecipients([...activities.activity.activeRecipients]);
    }
  }, [activities]);

  const handleArrowDownClick = () => {
    discussion === "true" ? navigate(-1) : showDiscussionsView(false);
    setSearchVisible(false);
    setRecipients([]);
    queryClient.invalidateQueries(`getPostActivities${storyId}`);
  };

  return (
    <div className="discussion__div">
      <NavDiscussions
        setFocusedDiscussion={setFocusedRecipient}
        focusedDiscussion={focusedRecipient}
        setSearchVisible={setSearchVisible}
        searchVisible={searchVisible}
        recipients={recipients}
        setRecipients={setRecipients}
        isLoadingRecipients={isLoadingActivities}
        handleArrowDownClick={handleArrowDownClick}
        storyId={storyId}
        storyDateId={storyDateId}
      >
        <ExpandableSearchInput
          isSearchActive={searchVisible}
          setSearchActive={setSearchVisible}
          searchPhrase={searchPhrase}
          setSearchPhrase={setSearchPhrase}
        />
      </NavDiscussions>
      {searchVisible ? (
        <div className="discussion__searchesContainer">
          <SearchResultsView
            setSearchVisible={setSearchVisible}
            searchValue={debouncedSearchPhrase}
            setRecipients={setRecipients}
            recipients={recipients}
            setFocusedDiscussion={setFocusedRecipient}
          />
        </div>
      ) : (
        <>
          <div className="discussion__commentsContainer">
            <SingleDiscussion
              storyId={storyId}
              storyDateId={storyDateId}
              discussionType={focusedRecipient.recipientType}
              recipientId={focusedRecipient.recipientId}
              setDiscussionLoaded={setDiscussionLoaded}
            />
          </div>
          <div className="discussion__typeCommentContainer">
            {focusedRecipient.recipientId === "World" &&
            focusedRecipient.recipientType === "World" &&
            isLoadingMySubscriptionStatus ? (
              <CircularProgress size={14} />
            ) : focusedRecipient.recipientId === "World" &&
              focusedRecipient.recipientType === "World" &&
              mySubscriptionStatus &&
              mySubscriptionStatus.subscriptionType ===
                SubscriptionType.Free ? (
              <div className="typeComment__getPremiumContainer">
                <GetPremiumButton />
                <div className="getPremiumContainer__text">
                  and join discussion
                </div>
              </div>
            ) : (
              <TypeComment
                storyDateId={storyDateId}
                storyId={storyId}
                recipientId={focusedRecipient.recipientId}
                discussionLoaded={discussionLoaded}
                discussionType={focusedRecipient.recipientType}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default DiscussionsOverview;

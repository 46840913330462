import styles from "./LastCard.module.css";
import GetPremiumButton from "../subscriptions/GetPremiumButton";
import { ReactComponent as LogoIcon } from "../components/icons/PaperPlanes.svg";
import SignInButtonWithRedirect from "../auth/SignInButtonWithRedirect";
interface LastCardProps {
  header: string;
  additionalInfo: string;
  showPremiumButton?: boolean;
  showSignInButton?: boolean;
}
function LastCard({
  header,
  additionalInfo,
  showPremiumButton,
  showSignInButton: showLogInButton,
}: LastCardProps) {
  return (
    <div className={styles.lastCard} key={`getPremiumCard`}>
      <h3>{header}</h3>
      <p>{additionalInfo}</p>
      {showPremiumButton && <GetPremiumButton />}
      {showLogInButton && (
        <SignInButtonWithRedirect
          customStyles={{ backgroundColor: "var(--dark-green)" }}
        />
      )}
      <div className={styles.logo}>
        <LogoIcon />
      </div>
    </div>
  );
}
export default LastCard;

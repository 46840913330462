import { QueryClient } from "react-query";
import { ContentResponse } from "../../types/ContentResponse";
import { FeedResponseData } from "../useFetchInfiniteFeed";
import { FilterStoriesType } from "../../types/FilterStoriesType";

interface UpdateFeedCacheProps {
  queryClient: QueryClient;
  pageNumber?: number;
  storyId: string;
  updatedPropertyName: FilterStoriesType;
  newValue: boolean;
}

export const updateFeedCache = ({
  queryClient,
  pageNumber,
  storyId,
  updatedPropertyName,
  newValue,
}: UpdateFeedCacheProps): ContentResponse | null => {
  const feedQueryData: { pages: FeedResponseData[] } | undefined =
    queryClient.getQueryData("content");

  if (feedQueryData && pageNumber !== undefined) {
    const pageToUpdate = feedQueryData.pages[pageNumber];

    if (!pageToUpdate || !pageToUpdate.Stories) {
      return null;
    }

    const storyToUpdateIndex = pageToUpdate.Stories.findIndex(
      (story) => story.Id === storyId
    );

    if (storyToUpdateIndex === -1) {
      return null;
    }

    const prevFeedStoryData: ContentResponse = {
      ...pageToUpdate.Stories[storyToUpdateIndex],
    };

    queryClient.cancelQueries(["content", `fetchStory${storyId}`]);

    queryClient.setQueryData<{ pages: FeedResponseData[] } | undefined>(
      "content",
      (data: { pages: FeedResponseData[] } | undefined) => {
        if (!data) return data;

        const newFeedStoryData = { ...data };

        if (
          newFeedStoryData.pages[pageNumber] &&
          newFeedStoryData.pages[pageNumber].Stories
        ) {
          const stories = newFeedStoryData.pages[pageNumber].Stories ?? [];

          if (updatedPropertyName === "Favorite") {
            stories[storyToUpdateIndex] = {
              ...prevFeedStoryData,
              Favorite: newValue,
              Likes: newValue
                ? prevFeedStoryData.Likes + 1
                : prevFeedStoryData.Likes - 1,
            };
          } else {
            stories[storyToUpdateIndex] = {
              ...prevFeedStoryData,
              Bookmark: newValue,
            };
          }
        }
        return newFeedStoryData;
      }
    );

    return prevFeedStoryData;
  }
  return null;
};

import { QueryClient } from "react-query";
import { ContentResponse } from "../../types/ContentResponse";
import { FilterTimelineApiResponse } from "../useFilterTimeline";
import { FilterStoriesType } from "../../types/FilterStoriesType";

interface UpdateFilterStoryCacheProps {
  queryClient: QueryClient;
  cacheType: FilterStoriesType;
  storyId: string;
  updatedPropertyName: FilterStoriesType;
  newValue: boolean;
  pageNumber?: number;
}

export const updateFilterStoryCache = ({
  queryClient,
  cacheType,
  pageNumber,
  storyId,
  updatedPropertyName,
  newValue,
}: UpdateFilterStoryCacheProps): ContentResponse | null => {
  const filterTimelineData: { pages: FilterTimelineApiResponse[] } | undefined =
    queryClient.getQueryData(`filter${cacheType}`);

  if (filterTimelineData && pageNumber !== undefined) {
    const pageToUpdate = filterTimelineData.pages[pageNumber];

    if (!pageToUpdate || !pageToUpdate.Stories) {
      return null;
    }

    const storyToUpdateIndex = pageToUpdate?.Stories?.findIndex(
      (story) => story.Id === storyId
    );

    if (storyToUpdateIndex === -1) {
      return null;
    }

    const prevFilterStoryCache: ContentResponse = {
      ...pageToUpdate.Stories[storyToUpdateIndex],
    };

    queryClient.cancelQueries(`filter${cacheType}`);

    queryClient.setQueryData<
      { pages: FilterTimelineApiResponse[] } | undefined
    >(
      `filter${cacheType}`,
      (data: { pages: FilterTimelineApiResponse[] } | undefined) => {
        if (!data) return data;

        const newFeedStoryData = { ...data };

        const stories = newFeedStoryData.pages[pageNumber]?.Stories ?? [];

        if (stories && storyToUpdateIndex !== -1) {
          if (updatedPropertyName === "Favorite") {
            stories[storyToUpdateIndex] = {
              ...prevFilterStoryCache,
              Favorite: newValue,
              Likes: newValue
                ? prevFilterStoryCache.Likes + 1
                : prevFilterStoryCache.Likes - 1,
            };
          } else {
            stories[storyToUpdateIndex] = {
              ...prevFilterStoryCache,
              Bookmark: newValue,
            };
          }
        }
        return newFeedStoryData;
      }
    );

    return prevFilterStoryCache;
  }
  return null;
};

import { QueryFunctionContext, QueryKey, useInfiniteQuery } from "react-query";
import { ContentResponse } from "../types/ContentResponse";
import { AxiosResponse } from "axios";
import { FeedResponseData } from "./useFetchInfiniteFeed";
import { apiClient } from "../../apiClient";
import { transformStoriesData } from "./functions/transformStoriesData";

interface UseFilterTimelineProps {
  type: "Bookmark" | "Favorite";
  numberOfStories: number;
}

export interface FilterTimelineApiResponse extends FeedResponseData {}

interface PageParam {
  LastReadNewsId?: string | null;
  DateId?: string | null;
}

interface TimelineFilters {
  DateId?: string | null;
  LastNewsId?: string | null;
  NumberOfNews: number;
  Bookmark?: boolean;
  Favorite?: boolean | null;
}

export const useFilterTimeline = ({
  type,
  numberOfStories,
}: UseFilterTimelineProps) => {
  async function postTimelineSearch(
    context: QueryFunctionContext<QueryKey, PageParam>
  ): Promise<FilterTimelineApiResponse | null> {
    let { pageParam } = context;
    let requestNumber = 0;

    while (requestNumber < 9) {
      try {
        const body: TimelineFilters = {
          DateId: pageParam?.DateId ?? null,
          LastNewsId: pageParam?.LastReadNewsId ?? null,
          NumberOfNews: numberOfStories,
          Bookmark: type === "Bookmark" ? true : undefined,
          Favorite: type === "Favorite" ? true : undefined,
        };
        const response: AxiosResponse<FeedResponseData> = await apiClient.post(
          "timeline/search",
          body
        );

        const data = response.data;

        if (data && data.Stories && data.Stories.length > 0) {
          const content: ContentResponse[] = data.Stories.map((story) =>
            transformStoriesData(story)
          );

          return {
            Stories: content,
            LastReadNewsId: data.LastReadNewsId,
            DateId: data.DateId,
          };
        } else {
          requestNumber += 1;
          pageParam = {
            LastReadNewsId: data.LastReadNewsId,
            DateId: data.DateId,
          };
        }
      } catch (error) {
        console.error("Error filtering timeline: ", error);
        throw error;
      }
    }
    return {
      Stories: [],
      LastReadNewsId: null,
      DateId: null,
    };
  }

  return useInfiniteQuery(`filter${type}`, postTimelineSearch, {
    getNextPageParam: (lastPage) => {
      if (lastPage && lastPage.DateId) {
        return {
          LastReadNewsId: lastPage.LastReadNewsId,
          DateId: lastPage.DateId,
        };
      } else return null;
    },
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  });
};

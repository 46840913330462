import { CardTypesEnum } from "../../types/CardTypesEnum";
import { ContentResponse, CardTypes } from "../../types/ContentResponse";
import { HeadlineStoryCardApiType } from "../../types/HeadlineStoryCardApiType";
import { StockCardApiType } from "../../types/StockCardApiType";
import { SummaryStoryCardApiType } from "../../types/SummaryStoryCardApiType";
import { TwittCardApiType } from "../../types/TwittCardApiType";

export const transformStoriesData = (data: ContentResponse) => {
  return {
    Categories: data.Categories,
    DateId: data.DateId,
    Language: data.Language,
    Id: data.Id,
    Bookmark: data.Bookmark,
    Favorite: data.Favorite,
    Likes: data.Likes,
    Source: data.Source,
    Cards: data.Cards.map((card: CardTypes) => {
      if (card?.Type === CardTypesEnum.HeadlineStoryCard) {
        return card as HeadlineStoryCardApiType;
      } else if (card?.Type === CardTypesEnum.SummaryStoryCard) {
        return card as SummaryStoryCardApiType;
      } else if (card?.Type === CardTypesEnum.StockCard) {
        return card as StockCardApiType;
      } else if (card?.Type === CardTypesEnum.TwittCard) {
        return card as TwittCardApiType;
      } else {
        return null;
      }
    }).filter((card) => card !== null),
  };
};

export const getDateId = (daysInThePast: number = 0): string => {
  const currentDate = new Date();

  // Subtract x days
  currentDate.setDate(currentDate.getDate() - daysInThePast);

  const year = currentDate.getFullYear();
  const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
  const day = currentDate.getDate().toString().padStart(2, "0");

  const formattedDate = `${year}${month}${day}`;

  return formattedDate;
};

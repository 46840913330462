import { AxiosResponse } from "axios";
import { apiClient } from "../../apiClient";
import { ContentResponse } from "../types/ContentResponse";
import { useQuery } from "react-query";
import { transformStoriesData } from "./functions/transformStoriesData";

interface UseFetchSingleStoryProps {
  storyDateId?: string;
  storyId?: string;
}

interface SingleStoryResponse {
  Story: ContentResponse;
}

export const useFetchSingleStory = ({
  storyDateId,
  storyId,
}: UseFetchSingleStoryProps) => {
  async function fetchStory(): Promise<ContentResponse> {
    try {
      const response: AxiosResponse<SingleStoryResponse> = await apiClient.get(
        `/stories/${storyDateId}/${storyId}`
      );
      const data = response.data.Story;
      const newContent: ContentResponse = transformStoriesData(data);
      return newContent;
    } catch (error) {
      console.error("Error fetching single story: ", error);
      throw error;
    }
  }

  return useQuery(`fetchStory${storyId}`, fetchStory, {
    enabled: !!storyDateId && !!storyId,
    refetchOnWindowFocus: false,
  });
};

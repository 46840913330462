import { apiClient } from "../../apiClient";
import { ContentResponse } from "../types/ContentResponse";
import { QueryFunctionContext, useInfiniteQuery } from "react-query";
import { AxiosError, AxiosResponse } from "axios";
import { useIsAuthenticated } from "@azure/msal-react";
import { transformStoriesData } from "./functions/transformStoriesData";

export interface FeedResponseData {
  Stories: ContentResponse[] | null;
  LastReadNewsId: string | null;
  DateId: string | null;
}

interface PageParam {
  LastReadNewsId?: string;
  DateId?: string;
}

export class ForbiddenTimelineError extends Error {
  constructor(code: string, message: string, status: string) {
    super(message);
    this.name = "ForbiddenTimelineError";
  }
}

export const useFetchInfiniteFeed = () => {
  const isAuthenticated = useIsAuthenticated();

  async function fetchContent(
    context: QueryFunctionContext<"content", PageParam>
  ): Promise<FeedResponseData | null> {
    try {
      const { pageParam } = context;
      const response: AxiosResponse<FeedResponseData> = await apiClient.get(
        "/timeline",
        {
          params: {
            lastnewsid: pageParam?.LastReadNewsId,
            dateid: pageParam?.DateId,
            number: 5,
          },
        }
      );

      const data = response.data;
      const content: ContentResponse[] = data.Stories
        ? data.Stories.map((story) => transformStoriesData(story))
        : [];

      return {
        Stories: content,
        LastReadNewsId: data.LastReadNewsId,
        DateId: data.DateId,
      };
    } catch (error) {
      if (error instanceof AxiosError) {
        if (
          error.response?.status === 403 &&
          error?.response?.data.code === "premium_required"
        ) {
          throw new ForbiddenTimelineError(
            error.response.data.code,
            error.response.data.message,
            error.response.data.status
          );
        }
      }
      console.error("Error fetching content", error);
      throw error;
    }
  }

  return useInfiniteQuery("content", fetchContent, {
    getNextPageParam: (lastPage) => {
      if (lastPage && lastPage.LastReadNewsId && lastPage.DateId) {
        return {
          LastReadNewsId: lastPage.LastReadNewsId,
          DateId: lastPage.DateId,
        };
      } else {
        return undefined; // No more pages
      }
    },
    enabled: isAuthenticated,
    staleTime: Infinity,
    cacheTime: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  });
};

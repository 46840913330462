import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { useEffect, useState } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { CssBaseline, ThemeProvider, createTheme } from "@mui/material";
import { darkScrollbar } from "@mui/material";
import "./App.css";
import Welcome from "./components/Welcome";
import SetUserPreferences from "./userPreferences/SetUserPreferences";
import AnimationScreen from "./components/AnimationScreen";
import HomePage from "./components/HomePage";
import Menu from "./components/Menu";
import BrowseMyGroups from "./community/groups/BrowseMyGroups";
import CreateNewGroup from "./community/groups/CreateNewGroup";
import SingleStory from "./feed/SingleStory";
import { useUserPreferencesData } from "./customHooks/useUserPreferencesData";
import MyProfile from "./profile/MyProfile";
import BigScreenError from "./components/BigScreenError";
import CommunityNav from "./community/CommunityNav";
import ContactsListWrapper from "./community/contacts/ContactsListWrapper";
import { useSignalRConnection } from "./notifications/hooks/useSignalRConnection";
import { useIsAuthenticated } from "@azure/msal-react";
import GroupOverviewWrapper from "./community/groups/GroupOverviewWrapper";
import ActivitiesDiscussionsNav from "./discussions/ActivitiesDiscussionsNav";
import ActivitiesDiscussionsWrapper from "./discussions/ActivitiesDiscussionsWrapper";
import Notifications from "./notifications/Notifications";
import useHandleNewPrivateDiscussionActivity from "./customHooks/useHandleNewPrivateDiscussionActivity ";
import FilterStoriesWrapper from "./feed/FilterStoriesWrapper";
import PricingPage from "./subscriptions/PricingPage";
import PublicUserProfileWrapper from "./profile/PublicUserProfileWrapper";
import { useResponsiveScreen } from "./customHooks/useResponsiveScreen";
import { getFeatureFlag } from "./featureFlags";
import TopDailyNews from "./feed/TopDailyNews";
import { ToastContainer } from "react-toastify";

const App = () => {
  const [darkMode, setDarkMode] = useState(false);
  const theme = createTheme({
    palette: {
      mode: darkMode ? "dark" : "light",
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          body: darkMode ? darkScrollbar() : null,
        },
      },
    },
  });

  const [desktopViewCookieEnabled, setDektopViewCookieEnabled] =
    useState<boolean>(false);

  const { isMobile, isTablet, isLargeScreen } = useResponsiveScreen();

  useEffect(() => {
    const flag = getFeatureFlag("desktopView");
    setDektopViewCookieEnabled(flag);
  }, []);

  const toggleDarkMode = () => {
    //TODO: call api and save color mode for user
    setDarkMode(!darkMode);
  };

  const { data: preferences, isLoading: isFetchingPreferences } =
    useUserPreferencesData();
  const isAuthenticated = useIsAuthenticated();
  useSignalRConnection(isAuthenticated);
  const preferredCategories = preferences
    ? preferences?.FavoriteCategories
    : [];

  useHandleNewPrivateDiscussionActivity();

  if (!isMobile && !desktopViewCookieEnabled) {
    return <BigScreenError />;
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <UnauthenticatedTemplate>
        <Routes>
          <Route path="/News/:newsDate/:newsId" element={<SingleStory />} />
          <Route path="/Top-Daily" element={<TopDailyNews />} />
          <Route path="/" element={<Welcome />} />
          <Route path="/*" element={<Navigate to="/" replace />} />
        </Routes>
      </UnauthenticatedTemplate>
      <AuthenticatedTemplate>
        {isFetchingPreferences ? (
          <AnimationScreen loadingText="LOADING..." showTopMenu={false} />
        ) : (
          <>
            {
              preferredCategories.length > 0 ? <Menu /> : null
              /* if preferredCategories.length > 0 then this is active user and we can show him menu  */
            }
            <ToastContainer limit={1} />
            <div
              className={
                isMobile
                  ? "appContentMobile"
                  : isTablet
                  ? "appContentTablet"
                  : isLargeScreen
                  ? "appContentLargeScreen"
                  : ""
              }
              // this div determines the available space for components after menu
            >
              {preferredCategories.length > 0 ? (
                <Routes>
                  <Route path="/" element={<HomePage />} />
                  <Route path="/Preferences" element={<SetUserPreferences />} />
                  <Route
                    path="/News/:newsDate/:newsId"
                    element={<SingleStory />}
                  />
                  <Route
                    path="/Community"
                    element={<Navigate to="/Community/Followers" replace />}
                  />
                  <Route path="/Notifications" element={<Notifications />} />
                  <Route path="/Community/*" element={<CommunityNav />}>
                    <Route path="Groups" element={<BrowseMyGroups />} />
                    <Route
                      path=":followType"
                      element={<ContactsListWrapper />}
                    />
                  </Route>
                  <Route
                    path="/Community/Groups/:groupId"
                    element={<GroupOverviewWrapper />}
                  />
                  <Route path="/Pricing" element={<PricingPage />} />
                  <Route
                    path="/Community/Groups/Create"
                    element={<CreateNewGroup />}
                  />
                  <Route path="/MyProfile" element={<MyProfile />} />
                  <Route
                    path="/MyProfile/:filterType"
                    element={<FilterStoriesWrapper />}
                  />
                  <Route
                    path="/User/:userId"
                    element={<PublicUserProfileWrapper />}
                  />

                  <Route
                    path="/Activities"
                    element={<Navigate to="/Activities/Private" replace />}
                  />
                  <Route
                    path="/Activities/*"
                    element={<ActivitiesDiscussionsNav />}
                  >
                    <Route
                      path=":accessType"
                      element={<ActivitiesDiscussionsWrapper />}
                    />
                  </Route>
                  <Route path="/Top-Daily" element={<TopDailyNews />} />
                  <Route
                    path="/News/:newsDate/:newsId/:discussion/:recipientId/:recipientType"
                    element={<SingleStory />}
                  />
                  <Route path="/*" element={<Navigate to="/" />} />
                  {/* Add more routes for the menu component here */}
                </Routes>
              ) : (
                <Routes>
                  <Route path="/" element={<HomePage />} />
                  <Route path="/*" element={<Navigate to="/" replace />} />
                </Routes>
              )}
            </div>
          </>
        )}
      </AuthenticatedTemplate>
    </ThemeProvider>
  );
};
export default App;

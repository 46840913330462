import { ToastOptions } from "react-toastify";

export const successToastOptions: ToastOptions = {
  position: "top-center",
  autoClose: 3000,
  hideProgressBar: false,
  closeOnClick: true,
  draggable: true,
  theme: "light",
};

export const errorToastOptions: ToastOptions = {
  position: "top-center",
  autoClose: 10000,
  hideProgressBar: false,
  closeOnClick: true,
  draggable: true,
  theme: "colored",
};

export const warningToastOptions: ToastOptions = {
  position: "top-center",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  draggable: true,
  theme: "light",
};

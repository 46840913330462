
import { CSSProperties } from "react";
import "./Button.css";
import { useHandleLogin } from "./useHandleLogin";
interface SignInButtonWithRedirectProps {
  customStyles?: CSSProperties;
}
export default function SignInButtonWithRedirect({
  customStyles,
}: SignInButtonWithRedirectProps) {
  const handleLogin = useHandleLogin();
  return (
    <button
      className="button_in_out"
      onClick={handleLogin}
      style={customStyles}
    >
      Sign in
    </button>
  );
}
